import Immutable from 'immutable';
import * as types from './types';
import {
  NO_CALL_PACKAGE_ID,
  DEFAULT_NUMBER_OF_EEROS,
} from '../../Broadband/constants';

const initialState = Immutable.fromJS({
  homephoneOptions: {
    landlineNumber: undefined,
    selectedCallPackageId: NO_CALL_PACKAGE_ID,
    callPackages: [],
  },
  tariffs: [],
  hasGoldDiscount: false,
  selectedTariff: null,
  selectedRouterName: null,
  selectedEeroDevices: DEFAULT_NUMBER_OF_EEROS,
  technology: null,
  requiresNewLine: false,
});

export default function counter(state = initialState, action) {
  switch (action.type) {
    case types.GET_BROADBAND_REQUEST_SUCCESS:
      return state.mergeDeep({
        tariffs: action.tariffs,
        hasGoldDiscount: action.hasGoldDiscount,
        homephoneOptions: {
          callPackages: action.callPackages,
        },
        requiresNewLine: action.requiresNewLine,
      });
    case types.SET_TARIFF:
      return state.merge({
        selectedTariff: action.tariff,
      });
    case types.SET_ROUTER:
      return state.merge({
        selectedRouterName: action.router,
      });
    case types.SET_TECHNOLOGY:
      return state.merge({
        technology: action.technology,
      });
    case types.SET_EEROS:
      return state.merge({
        selectedEeroDevices: action.numOfEeros,
      });
    case types.SET_SELECTED_CALL_PACKAGE:
      return state.mergeDeep({
        homephoneOptions: {
          selectedCallPackageId: action.callPackageId,
        },
      });
    case types.RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
