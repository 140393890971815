import { select, call, put } from 'redux-saga/effects';
import { preselectFailure } from '../actions';

import getBroadbandTariffs from 'redux/modules/Broadband/service/getBroadbandTariffs';
import {
  enrichTariffs,
  normaliseCallPackages,
} from 'redux/modules/Broadband/saga';
import {
  NO_CALL_PACKAGE,
  PACKAGE_NAME_EERO,
  TECHNOLOGY_FTTP,
} from 'redux/modules/Broadband/constants';
import { FTTP_TARIFFS } from './constants';
import {
  getBroadbandRequestSuccess,
  setTariff,
  setRouter,
  resetStore,
  setEeros,
  setSelectedCallPackage,
} from './actions';
import {
  FIBRE_TARIFF,
  ULTRA_TARIFF,
  ROUTER,
  EEROS,
  CALL_PACKAGE,
  PREFERRED_TARIFF,
  SOGEA_TARIFF,
} from './defaults';
import {
  getTariffs,
  getSelectedRouter,
  getSelectedTariffId,
  getStore,
  getHomephoneOptions,
  getSelectedEeroDevices,
} from './api';
import putBroadband from 'redux/modules/Broadband/service/putBroadband';
import { preselectSuccess } from 'redux/modules/Broadband/actions';
import { mapTariffs } from 'redux/modules/Broadband/helpers';

export function* selectProducts({
  preferredTariff = PREFERRED_TARIFF,
  fibreTariff = FIBRE_TARIFF,
  ultraTariff = ULTRA_TARIFF,
  sogeaTariff = SOGEA_TARIFF,
  router = ROUTER,
  eeros = EEROS,
  callPackage = CALL_PACKAGE,
}) {
  try {
    const { data } = yield call(getBroadbandTariffs);
    let tariffs = data ? data.tariffs : null;

    if (!tariffs) {
      throw new Error('No broadband tariffs available');
    }

    const hasPreselectedData = tariffs.find(
      (tariff) =>
        [fibreTariff, ultraTariff, sogeaTariff].includes(tariff.meta.id) &&
        tariff.equipmentPackage.find((equipment) => equipment.name === router)
    );

    if (!hasPreselectedData) {
      throw new Error('Preselected broadband tariff is not available');
    }

    tariffs = enrichTariffs(tariffs);
    const hasGoldDiscount = Boolean(data?.hasGoldDiscount);
    const requiresNewLine = Boolean(data?.requiresNewLine);

    const callPackages = [
      ...normaliseCallPackages(data.callPackages),
      NO_CALL_PACKAGE,
    ];

    const { id: callPackageId } = callPackages.find(
      ({ name }) => name === callPackage
    );

    if (!callPackageId) {
      throw new Error('Call package is not available');
    }

    yield put(
      getBroadbandRequestSuccess(
        tariffs,
        hasGoldDiscount,
        callPackages,
        requiresNewLine
      )
    );
    yield call(
      addProducts,
      preferredTariff,
      fibreTariff,
      ultraTariff,
      sogeaTariff,
      router,
      eeros,
      callPackageId
    );
  } catch (error) {
    yield put(preselectFailure(error));
  }
}

function* addProducts(
  preferredTariff,
  fibreTariff,
  ultraTariff,
  sogeaTariff,
  router,
  eeros,
  callPackage
) {
  const tariffs = yield select(getTariffs);
  let selectedTariff = findPreferredTariff(
    tariffs,
    preferredTariff,
    fibreTariff,
    ultraTariff
  );
  if (!selectedTariff) {
    selectedTariff = tariffs.find((tariff) => tariff.meta.id === sogeaTariff);
  }
  const [mappedSelectedTariff] = mapTariffs([selectedTariff]);
  yield put(setTariff(mappedSelectedTariff));
  yield put(setRouter(router));
  yield put(setEeros(eeros));
  yield put(setSelectedCallPackage(callPackage));
}

const findPreferredTariff = (
  tariffs,
  preferredTechnology,
  fibreTariff,
  ultraTariff
) => {
  const preferredTariffName =
    preferredTechnology === TECHNOLOGY_FTTP ? fibreTariff : ultraTariff;
  const backUpTariffName =
    preferredTechnology === TECHNOLOGY_FTTP ? ultraTariff : fibreTariff;
  let selectedTariff = tariffs.find(
    (tariff) => tariff.meta.id === preferredTariffName
  );
  if (!selectedTariff) {
    selectedTariff = tariffs.find(
      (tariff) => tariff.meta.id === backUpTariffName
    );
  }
  return selectedTariff;
};

export function* putProducts() {
  const tariff = yield select(getSelectedTariffId);
  const router = yield select(getSelectedRouter);
  const includeHomephone = !FTTP_TARIFFS.includes(tariff);
  const homephoneOptions = yield select(getHomephoneOptions);
  const selectedEeroDevices =
    router === PACKAGE_NAME_EERO ? yield select(getSelectedEeroDevices) : null;

  try {
    yield call(
      putBroadband,
      tariff,
      router,
      selectedEeroDevices,
      includeHomephone,
      homephoneOptions
    );
  } catch (error) {
    yield put(preselectFailure(error));
  }
}

export function* migrateStore() {
  const preselectState = yield select(getStore);
  yield put(preselectSuccess(preselectState));
  yield put(resetStore());
}
